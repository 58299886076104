<template>
  <div>
    <xianshizhi></xianshizhi>

    <jilu></jilu>
  </div>
</template>

<script>
import Jilu from "../components/jilu.vue";

import xianshizhi from "../components/xianshizhi.vue";

export default {
  data() {
    return {};
  },
  components: {
    xianshizhi,
    Jilu,
  },
};
</script>
