import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router';
import './api/mock'


Vue.use(ElementUI);


Vue.config.productionTip = false

new Vue({
  router,

  render: h => h(App),

  beforeCreate() {

    Vue.prototype.$bus = this//安装全局事件总线



  },
}).$mount('#app')


