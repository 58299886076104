<template>
  <div class="neibu">
    <el-table
      :data="tableDataT"
      max-height="400"
      border
      style="width: 100%"
      header-align="center"
      align="center"
      ref="elTable"
    >
      <el-table-column
        v-for="(item, index) in tableDataT[0]"
        :key="index"
        :prop="index"
        :label="index"
        width="100%"
        align="center"
      >
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
// import { getData } from '../api';

import { lastDataPoint } from "../api/cs.js";

import dayjs from "dayjs";

//导出EXCEL
import { saveAs } from "file-saver";
// import XLSX from 'xlsx';
import * as XLSX from "xlsx/xlsx.mjs";

export default {
  data() {
    return {
      timer: "",
      value: 0,
      kaishijilu: false,

      tableDataT: [
        {
          Time: "",
          T1: "",
          T2: "",
          T3: "",
          T4: "",
          T5: "",
          T6: "",
          T7: "",
          T8: "",
          T9: "",
          T10: "",
          // T11: "20.00",
          // T12: "20.00",
        },
      ],

      tableDataT3: [{}],
    };
  },

  methods: {
    exportToExcel() {
      // 获取表格的DOM元素
      const elTable = this.$refs.elTable;
      const wb = XLSX.utils.table_to_book(elTable.$el);
      // 生成Excel的配置项
      const wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array",
      });
      try {
        // 如果是IE浏览器，使用Blob通过navigator.msSaveBlob保存
        if (navigator.msSaveBlob) {
          navigator.msSaveBlob(
            new Blob([wbout], { type: "application/octet-stream" }),
            "温度数据.xlsx"
          );
        } else {
          // 其他浏览器使用file-saver保存
          saveAs(
            new Blob([wbout], { type: "application/octet-stream" }),
            "温度数据.xlsx"
          );
        }
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, wbout);
      }
      return wbout;
    },

    ////温湿度传感器，暂停
    // valChange2(data)
    // {
    //   this.tableDataT2[0]=data
    // },

    ////有人云-温湿度传感器
    // valChange() {

    //     //循环执行的方法

    //     // getData().then((data)=>{

    //     //   this.tableDataT.push(data.data.tableDataT[0])

    //     // });

    //     // this.tableDataT.push(this.tableDataT2[0])

    //     lastDataPoint([
    //           {
    //               cusdeviceNo: '0000225119000004',
    //               dataPointId: '23851406'
    //           },
    //           {
    //               cusdeviceNo: '0000225119000004',
    //               dataPointId: '23851407'
    //           },
    //           {
    //               cusdeviceNo: '0000225119000004',
    //               dataPointId: '23851408'
    //           },
    //           {
    //               cusdeviceNo: '0000225119000004',
    //               dataPointId: '23851409'
    //           },
    //           {
    //               cusdeviceNo: '0000225119000004',
    //               dataPointId: '23851423'
    //           },
    //           {
    //               cusdeviceNo: '0000225119000004',
    //               dataPointId: '23851429'
    //           },

    //           {
    //               cusdeviceNo: '0000225119000004',
    //               dataPointId: '23851425'
    //           },
    //           {
    //               cusdeviceNo: '0000225119000004',
    //               dataPointId: '23851463'
    //           },
    //           {
    //               cusdeviceNo: '0000225119000004',
    //               dataPointId: '23851426'
    //           },
    //           {
    //               cusdeviceNo: '0000225119000004',
    //               dataPointId: '23851464'
    //           },
    //           {
    //               cusdeviceNo: '0000225119000004',
    //               dataPointId: '23851427'
    //           },
    //           {
    //               cusdeviceNo: '0000225119000004',
    //               dataPointId: '23851465'
    //           },

    //           {
    //               cusdeviceNo: '0000225119000004',
    //               dataPointId: '23851428'
    //           },
    //           {
    //               cusdeviceNo: '0000225119000004',
    //               dataPointId: '23851466'
    //           },
    //           {
    //               cusdeviceNo: '0000225119000004',
    //               dataPointId: '24446149'
    //           },
    //           {
    //               cusdeviceNo: '0000225119000004',
    //               dataPointId: '24446151'
    //           },
    //           {
    //               cusdeviceNo: '0000225119000004',
    //               dataPointId: '24446150'
    //           },
    //           {
    //               cusdeviceNo: '0000225119000004',
    //               dataPointId: '24446152'
    //           },

    //       ]).then(res => {
    //         console.log(res.data);

    //        let tableDataT2=[
    //           {

    //                       Time: "",
    //                       T1: "",
    //                       T2: "",
    //                       T3: "",
    //                       T4: "",
    //                       T5: "",
    //                       T6: "",
    //                       T7: "",
    //                       T8: "",
    //                       T9: "",

    //           },
    //           ]

    //         tableDataT2[0].Time=dayjs(res.data.data.list[0].time).format('HH:mm')
    //         tableDataT2[0].T1=res.data.data.list[0].value
    //         tableDataT2[0].T2=res.data.data.list[2].value
    //         tableDataT2[0].T3=res.data.data.list[4].value
    //         tableDataT2[0].T4=res.data.data.list[6].value
    //         tableDataT2[0].T5=res.data.data.list[8].value
    //         tableDataT2[0].T6=res.data.data.list[10].value
    //         tableDataT2[0].T7=res.data.data.list[12].value
    //         tableDataT2[0].T8=res.data.data.list[14].value
    //         tableDataT2[0].T9=res.data.data.list[16].value

    //         // 使用展开运算符添加新元素
    //         // this.tableDataT = [...this.tableDataT, tableDataT2[0]];
    //         this.tableDataT.unshift(tableDataT2[0])

    //         // this.tableDataT.unshift(this.tableDataT2[0])
    //         // this.tableDataT.push(this.tableDataT2[0])

    //         // console.log('T2',tableDataT2[0])
    //         // console.log('T2',this.tableDataT[0])
    //       })

    //   },

    // //有人云-温度采集模块
    // valChange2() {

    //   //循环执行的方法

    //   // getData().then((data)=>{

    //   //   this.tableDataT.push(data.data.tableDataT[0])

    //   // });

    //   // this.tableDataT.push(this.tableDataT2[0])

    //   lastDataPoint([
    //         {
    //             cusdeviceNo: '0000225119000007',
    //             dataPointId: '24114177'
    //         },
    //         {
    //             cusdeviceNo: '0000225119000007',
    //             dataPointId: '24114178'
    //         },
    //         {
    //             cusdeviceNo: '0000225119000007',
    //             dataPointId: '24114179'
    //         },
    //         {
    //             cusdeviceNo: '0000225119000007',
    //             dataPointId: '24114180'
    //         },
    //         {
    //             cusdeviceNo: '0000225119000007',
    //             dataPointId: '24114190'
    //         },
    //         {
    //             cusdeviceNo: '0000225119000007',
    //             dataPointId: '24114191'
    //         },

    //         {
    //             cusdeviceNo: '0000225119000007',
    //             dataPointId: '24114192'
    //         },
    //         {
    //             cusdeviceNo: '0000225119000007',
    //             dataPointId: '24114194'
    //         },
    //         {
    //             cusdeviceNo: '0000225119000007',
    //             dataPointId: '24114196'
    //         },
    //         {
    //             cusdeviceNo: '0000225119000007',
    //             dataPointId: '24114197'
    //         },

    //     ]).then(res => {
    //       console.log(res.data);

    //      let tableDataT2=[
    //         {

    //                     Time: "",
    //                     T1: "",
    //                     T2: "",
    //                     T3: "",
    //                     T4: "",
    //                     T5: "",
    //                     T6: "",
    //                     T7: "",
    //                     T8: "",
    //                     T9: "",
    //                     T10: "",

    //         },
    //         ]

    //       tableDataT2[0].Time=dayjs(res.data.data.list[0].time).format('HH:mm')
    //       tableDataT2[0].T1=res.data.data.list[0].value
    //       tableDataT2[0].T2=res.data.data.list[1].value
    //       tableDataT2[0].T3=res.data.data.list[2].value
    //       tableDataT2[0].T4=res.data.data.list[3].value
    //       tableDataT2[0].T5=res.data.data.list[4].value
    //       tableDataT2[0].T6=res.data.data.list[5].value
    //       tableDataT2[0].T7=res.data.data.list[6].value
    //       tableDataT2[0].T8=res.data.data.list[7].value
    //       tableDataT2[0].T9=res.data.data.list[8].value
    //       tableDataT2[0].T10=res.data.data.list[9].value

    //       // 使用展开运算符添加新元素
    //       // this.tableDataT = [...this.tableDataT, tableDataT2[0]];
    //       this.tableDataT.unshift(tableDataT2[0])

    //       // this.tableDataT.unshift(this.tableDataT2[0])
    //       // this.tableDataT.push(this.tableDataT2[0])

    //       // console.log('T2',tableDataT2[0])
    //       // console.log('T2',this.tableDataT[0])
    //     })

    // },

    jieshu() {
      clearInterval(this.timer);
    },

    //全局事件总线收到点击开始采集按钮的数据
    zhuangtai(jieshouzhuangtai) {
      // console.log(jieshouzhuangtai)

      // return(jieshouzhuangtai)
      this.kaishijilu = jieshouzhuangtai;
      // console.log(this.kaishijilu)

      // console.log(this.kaishijilu)
    },

    //全局事件总线收到的采集频率
    pinlv(jieshoupinlv) {
      // console.log(this.kaishijilu)
      if (this.kaishijilu === true) {
        clearInterval(this.timer);
        //循环执行代码
        this.timer = setInterval(this.quanshujuTable3, jieshoupinlv * 1000); // 注意: 第一个参数为方法名的时候不要加括号;
      } else if (this.kaishijilu === false) {
        clearInterval(this.timer);
      }
    },

    //全局事件总线收到清空时
    qingkong(qingkong2) {
      this.tableDataT = [
        {
          Time: "",
          T1: "",
          T2: "",
          T3: "",
          T4: "",
          T5: "",
          T6: "",
          T7: "",
          T8: "",
          T9: "",
          T10: "",
          // T11: "20.00",
          // T12: "20.00",
        },
      ];
    },

    geishuju() {
      //收到Aside发的信号后给Aside传值
      this.$bus.$emit("wendushuju", this.tableDataT);
    },

    //tlink-温湿度传感器
    quanshujuTable3(Table) {
      let tableDataT2 = [
        {
          Time: "",
          T1: "",
          T2: "",
          T3: "",
          T4: "",
          T5: "",
          T6: "",
          T7: "",
          T8: "",
          T9: "",
          T10: "",
        },
      ];

      tableDataT2[0].Time = dayjs(new Date().toLocaleString()).format(
        "HH:mm:ss"
      );
      tableDataT2[0].T1 = this.tableDataT3[0].T1;
      tableDataT2[0].T2 = this.tableDataT3[0].T2;
      tableDataT2[0].T3 = this.tableDataT3[0].T3;
      tableDataT2[0].T4 = this.tableDataT3[0].T4;
      tableDataT2[0].T5 = this.tableDataT3[0].T5;
      tableDataT2[0].T6 = this.tableDataT3[0].T6;
      tableDataT2[0].T7 = this.tableDataT3[0].T7;
      tableDataT2[0].T8 = this.tableDataT3[0].T8;
      tableDataT2[0].T9 = this.tableDataT3[0].T9;
      tableDataT2[0].T10 = this.tableDataT3[0].T10;

      this.tableDataT.unshift(tableDataT2[0]);
    },

    quanshujuTable2(Table) {
      // this.tableDataT3[0].Time = Table[0].Time;
      this.tableDataT3[0].T1 = Table[0].T1;
      this.tableDataT3[0].T2 = Table[0].T2;
      this.tableDataT3[0].T3 = Table[0].T3;
      this.tableDataT3[0].T4 = Table[0].T4;
      this.tableDataT3[0].T5 = Table[0].T5;
      this.tableDataT3[0].T6 = Table[0].T6;
      this.tableDataT3[0].T7 = Table[0].T7;
      this.tableDataT3[0].T8 = Table[0].T8;
      this.tableDataT3[0].T9 = Table[0].T9;
      this.tableDataT3[0].T10 = Table[0].T10;
    },
  },

  mounted() {
    // getData().then((data)=>{

    //   this.tableDataT=data.data.tableDataT

    // }),

    this.$bus.$on("jiluzhuangtai", this.zhuangtai);
    this.$bus.$on("caijipinlv", this.pinlv);
    this.$bus.$on("qingkong", this.qingkong);

    this.$bus.$on("geiwendushuju", this.geishuju); //等待Aside发信号，收到信号后再发信号，详见函数

    // 先启动，不会合并数组（Aside）
    this.$bus.$on("daochushuju", this.exportToExcel); //

    // this.$bus.$on('chuanwendushuju',this.valChange2)//把显示的数据传给记录组件，注意别和geishuju混了，那个是记录的大数组表格传给aside，然后存成excel用的

    //Tlink-温度采集模块
    this.$bus.$on("quanshujuTable", this.quanshujuTable2);
  },

  beforeDestroy() {
    clearInterval(this.timer);
    this.$bus.$off("jiluzhuangtai");
    this.$bus.$off("caijipinlv");
    this.$bus.$off("qingkong");
    this.$bus.$off("geiwendushuju");
    // this.$bus.$off('chuanwendushuju');

    this.$bus.$off("daochushuju");
    this.$bus.$off("quanshujuTable");
  },
};
</script>

<style scoped>
.neibu {
  border-color: rgba(54, 206, 24, 0);
  border-width: 0px;
  border: none;
  text-align: center;
}

.el-table {
  padding-left: 175px;
  padding-top: 20px;

  background-color: rgba(226, 212, 212, 0);

  border-color: rgba(54, 206, 24, 0);
}

.el-table::before {
  height: 0px;
}

.el-table--border::after {
  width: 0px;
}

.el-button {
  margin-top: 10px;
}
</style>
