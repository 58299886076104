import axios from "axios";
// let baseUrl = "https://app.dtuip.com"; // /abc
let baseUrl = "abc"; // /
// 获取token
export const getOauthToken = () => {
  return axios({
    url:
      baseUrl +
      "/oauth/token?grant_type=password&username=技术部测试&password=lcjl2024",
    method: "POST",
    headers: {
      "Content-Type": "text/plain",
      "cache-control": "no-cache",
      Accept: "*/*",
      Host: "app.dtuip.com",
      Connection: "keep-alive",
      authorization:
        "Basic ZDNmNGRjZDBlZTcwNDlkMzg2YzBmNzZmMzBlMTAyY2M6MjU5NGI4MjdlODY5NDUwMDk5YTY4ODhjN2QwMzZmMzE=",
    },
  });
};
// 刷新token
export const getNewOauthToken = (params) => {
  return axios({
    url:
      baseUrl +
      `/oauth/token?grant_type=refresh_token&refresh_token=${params.refresh_token}&client_id=${params.client_id}&client_secret=${params.client_secret}`,
    method: "POST",
    headers: {
      "Content-Type": "text/plain",
      "cache-control": "no-cache",
      Host: "app.dtuip.com",
    },
  });
};
// 分页获取用户设备传感器(含传感器实时数据)
export const getSingleDeviceDatas = (accessToken, clientId, params) => {
  return axios({
    url: baseUrl + "/api/device/getSingleDeviceDatas",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "cache-control": "no-cache",
      Host: "app.dtuip.com",
      Authorization: `Bearer ${accessToken}`,
      tlinkAppId: clientId,
    },
    data: params,
  });
};
