// import http from '../utils/request';

// //请求首页数据

// export const getData = () => {


//     //返回一个promise对象
//     return http.get('/home/getData')


// }

// export const getToken = () => {


//     //返回一个promise对象
//     return http.post('/usrCloud/V6/user/getAuthToken', {

//         appKey: '37gemNQj',
//         appSecret: '0o2b7k6o6nwb7jphifg4oqbf03ce6sf6',

//     },)


// }

// getToken().then((data) => {

//     console.log(data)
//     console.log(data.data.data["X-Access-Token"])
//     return data.data.data["X-Access-Token"]


// });

import axios from "axios";
// 创建一个axios实例
const http = axios.create({
    timeout: 15000, // 请求超时时间
    withCredentials: false,
    headers: {
        "Content-Type": "application/json",
    },
});

// 请求拦截器
http.interceptors.request.use(
    (config) => {
        console.log(config);
        // 在此处可以进行其他处理，例如验证token等
        return config;
    },
    (error) => {
        // 发生错误时的处理
        return Promise.reject(error);
    }
);

// 封装GET请求
export const get = (url, data) => {
    return http.get(url, data);
};

// 封装POST请求
export const post = (url, data) => {
    return http.post(url, data);
};


export default http;