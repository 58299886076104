<template>
  <div class="neibu">
    <el-table
      :data="tableDataH"
      max-height="400"
      border
      style="width: 100%"
      header-align="center"
      align="center"
      ref="elTable"
    >
      <el-table-column
        v-for="(item, index) in tableDataH[0]"
        :key="index"
        :prop="index"
        :label="index"
        width="100%"
        align="center"
      >
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
// import { getData } from '../api';

import { lastDataPoint } from "../api/cs.js";

import dayjs from "dayjs";

//导出EXCEL
import { saveAs } from "file-saver";
// import XLSX from 'xlsx';
import * as XLSX from "xlsx/xlsx.mjs";

export default {
  data() {
    return {
      timer: "",
      value: 0,
      kaishijilu: false,
      tableDataH: [
        {
          Time: "",
          H1: "",
          H2: "",
          H3: "",
          H4: "",
          H5: "",
          H6: "",
          H7: "",
          H8: "",
          H9: "",
          H10: "",
          // H11: "20.00",
          // H12: "20.00",
        },
      ],

      tableDataH3: [{}],
    };
  },

  methods: {
    exportToExcel() {
      // 获取表格的DOM元素
      const elTable = this.$refs.elTable;
      const wb = XLSX.utils.table_to_book(elTable.$el);
      // 生成Excel的配置项
      const wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array",
      });
      try {
        // 如果是IE浏览器，使用Blob通过navigator.msSaveBlob保存
        if (navigator.msSaveBlob) {
          navigator.msSaveBlob(
            new Blob([wbout], { type: "application/octet-stream" }),
            "湿度数据.xlsx"
          );
        } else {
          // 其他浏览器使用file-saver保存
          saveAs(
            new Blob([wbout], { type: "application/octet-stream" }),
            "湿度数据.xlsx"
          );
        }
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, wbout);
      }
      return wbout;
    },

    //   //湿度采集，暂停
    // valChange() {

    //     //循环执行的方法
    //     // this.value++;

    //     // getData().then((data)=>{

    //     //   this.tableDataH.push(data.data.tableDataH[0])

    //     // });

    //   lastDataPoint([
    //           {
    //               cusdeviceNo: '0000225119000004',
    //               dataPointId: '23851406'
    //           },
    //           {
    //               cusdeviceNo: '0000225119000004',
    //               dataPointId: '23851407'
    //           },
    //           {
    //               cusdeviceNo: '0000225119000004',
    //               dataPointId: '23851408'
    //           },
    //           {
    //               cusdeviceNo: '0000225119000004',
    //               dataPointId: '23851409'
    //           },
    //           {
    //               cusdeviceNo: '0000225119000004',
    //               dataPointId: '23851423'
    //           },
    //           {
    //               cusdeviceNo: '0000225119000004',
    //               dataPointId: '23851429'
    //           },

    //           {
    //               cusdeviceNo: '0000225119000004',
    //               dataPointId: '23851425'
    //           },
    //           {
    //               cusdeviceNo: '0000225119000004',
    //               dataPointId: '23851463'
    //           },
    //           {
    //               cusdeviceNo: '0000225119000004',
    //               dataPointId: '23851426'
    //           },
    //           {
    //               cusdeviceNo: '0000225119000004',
    //               dataPointId: '23851464'
    //           },
    //           {
    //               cusdeviceNo: '0000225119000004',
    //               dataPointId: '23851427'
    //           },
    //           {
    //               cusdeviceNo: '0000225119000004',
    //               dataPointId: '23851465'
    //           },

    //           {
    //               cusdeviceNo: '0000225119000004',
    //               dataPointId: '23851428'
    //           },
    //           {
    //               cusdeviceNo: '0000225119000004',
    //               dataPointId: '23851466'
    //           },
    //           {
    //               cusdeviceNo: '0000225119000004',
    //               dataPointId: '24446149'
    //           },
    //           {
    //               cusdeviceNo: '0000225119000004',
    //               dataPointId: '24446151'
    //           },
    //           {
    //               cusdeviceNo: '0000225119000004',
    //               dataPointId: '24446150'
    //           },
    //           {
    //               cusdeviceNo: '0000225119000004',
    //               dataPointId: '24446152'
    //           },

    //       ]).then(res => {
    //         console.log(res.data);

    //        let tableDataH2= [
    //         {

    //               Time: "",
    //               H1: "",
    //               H2:"",
    //               H3: "",
    //               H4: "",
    //               H5: "",
    //               H6: "",
    //               H7: "",
    //               H8: "",
    //               H9: "",

    //         },
    //         ]

    //         tableDataH2[0].Time=dayjs(res.data.data.list[0].time).format('HH:mm')
    //         tableDataH2[0].H1=res.data.data.list[1].value
    //         tableDataH2[0].H2=res.data.data.list[3].value
    //         tableDataH2[0].H3=res.data.data.list[5].value
    //         tableDataH2[0].H4=res.data.data.list[7].value
    //         tableDataH2[0].H5=res.data.data.list[9].value
    //         tableDataH2[0].H6=res.data.data.list[11].value
    //         tableDataH2[0].H7=res.data.data.list[13].value
    //         tableDataH2[0].H8=res.data.data.list[15].value
    //         tableDataH2[0].H9=res.data.data.list[17].value

    //          // 使用展开运算符添加新元素
    //         this.tableDataH.unshift(tableDataH2[0])

    //         // this.tableDataH.unshift(this.tableDataH2[0])
    //         // this.tableDataT.push(this.tableDataT2[0])

    //         // console.log('H2',this.tableDataH2[0])
    //         // console.log('H2',this.tableDataH[0])

    //       })

    //   },

    jieshu() {
      clearInterval(this.timer);
    },

    //全局事件总线收到点击开始采集按钮的数据
    zhuangtai(jieshouzhuangtai) {
      this.kaishijilu = jieshouzhuangtai;
      // console.log(this.kaishijilu)

      console.log(this.kaishijilu);
    },

    //全局事件总线收到的采集频率
    pinlv(jieshoupinlv) {
      // console.log(this.kaishijilu)
      if (this.kaishijilu === true) {
        //温湿度传感器，先暂停
        clearInterval(this.timer);
        //循环执行代码
        this.timer = setInterval(this.quanshujuTable3, jieshoupinlv * 1000); // 注意: 第一个参数为方法名的时候不要加括号;
      } else if (this.kaishijilu === false) {
        clearInterval(this.timer);
      }
    },
    //全局事件总线收到清空时
    qingkong(qingkong2) {
      this.tableDataH = [
        {
          Time: "",
          H1: "",
          H2: "",
          H3: "",
          H4: "",
          H5: "",
          H6: "",
          H7: "",
          H8: "",
          H9: "",
          H10: "",
          // H11:"",
          // H12:"",
        },
      ];
    },

    geishuju() {
      //收到Aside发的信号后给Aside传值
      this.$bus.$emit("shidushuju", this.tableDataH);
    },

    //tlink-温湿度传感器
    quanshujuTable2(Table) {
      // this.tableDataH3[0].Time = Table[0].Time;
      this.tableDataH3[0].H1 = Table[0].H1;
      this.tableDataH3[0].H2 = Table[0].H2;
      this.tableDataH3[0].H3 = Table[0].H3;
      this.tableDataH3[0].H4 = Table[0].H4;
      this.tableDataH3[0].H5 = Table[0].H5;
      this.tableDataH3[0].H6 = Table[0].H6;
      this.tableDataH3[0].H7 = Table[0].H7;
      this.tableDataH3[0].H8 = Table[0].H8;
      this.tableDataH3[0].H9 = Table[0].H9;
      this.tableDataH3[0].H10 = Table[0].H10;
    },

    quanshujuTable3(Table) {
      let tableDataH2 = [
        {
          Time: "",
          H1: "",
          H2: "",
          H3: "",
          H4: "",
          H5: "",
          H6: "",
          H7: "",
          H8: "",
          H9: "",
          H10: "",
        },
      ];

      tableDataH2[0].Time = dayjs(new Date().toLocaleString()).format(
        "HH:mm:ss"
      );
      tableDataH2[0].H1 = this.tableDataH3[0].H1;
      tableDataH2[0].H2 = this.tableDataH3[0].H2;
      tableDataH2[0].H3 = this.tableDataH3[0].H3;
      tableDataH2[0].H4 = this.tableDataH3[0].H4;
      tableDataH2[0].H5 = this.tableDataH3[0].H5;
      tableDataH2[0].H6 = this.tableDataH3[0].H6;
      tableDataH2[0].H7 = this.tableDataH3[0].H7;
      tableDataH2[0].H8 = this.tableDataH3[0].H8;
      tableDataH2[0].H9 = this.tableDataH3[0].H9;
      tableDataH2[0].H10 = this.tableDataH3[0].H10;

      this.tableDataH.unshift(tableDataH2[0]);
    },
  },

  mounted() {
    this.$bus.$on("jiluzhuangtai", this.zhuangtai);
    this.$bus.$on("caijipinlv", this.pinlv);
    this.$bus.$on("qingkong", this.qingkong);

    this.$bus.$on("geishidushuju", this.geishuju); //等待Aside发信号，收到信号后再发信号，详见函数

    // 先启动，不会合并数组（Aside）
    this.$bus.$on("daochushuju", this.exportToExcel); //

    //Tlink-温度采集模块
    this.$bus.$on("quanshujuTable", this.quanshujuTable2);
  },

  beforeDestroy() {
    clearInterval(this.timer);
    this.$bus.$off("jiluzhuangtai");
    this.$bus.$off("caijipinlv");
    this.$bus.$off("qingkong");
    this.$bus.$off("geishidushuju");

    this.$bus.$off("daochushuju");
    this.$bus.$off("quanshujuTable");
  },
};
</script>

<style scoped>
.neibu {
  border-color: rgba(54, 206, 24, 0);
  border-width: 0px;
  border: none;
  text-align: center;
}

.el-table {
  padding-left: 175px;
  padding-top: 20px;

  background-color: rgba(226, 212, 212, 0);

  border-color: rgba(54, 206, 24, 0);
}

.el-table::before {
  height: 0px;
}

.el-table--border::after {
  width: 0px;
}

.el-button {
  margin-top: 10px;
}
</style>
