<template>
  <div id="app">
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
import {
  getOauthToken,
  getNewOauthToken,
  getSingleDeviceDatas,
} from "../src/api/index";

import axios from "axios";

import { aaaa } from "../src/api/qingqiu";

import dayjs from "dayjs";

export default {
  name: "App",
  data() {
    return {
      tableData: [
        {
          Time: "Time",
          T1: "T1",
          T2: "T2",
          T3: "T3",
          T4: "T4",
          T5: "T5",
          T6: "T6",
          T7: "T7",
          T8: "T8",
          T9: "T9",
          T10: "T10",
          T11: "T11",
          T12: "T12",
          H1: "H1",
          H2: "H2",
          H3: "H3",
          H4: "H4",
          H5: "H5",
          H6: "H6",
          H7: "H7",
          H8: "H8",
          H9: "H9",
          H10: "H10",
          H11: "H11",
          H12: "H12",
        },
      ],
    };
  },
  mounted() {
    // this.getToken();
    // setInterval(() => {
    //   this.getList();
    // }, 4000);

    this.test();

    // setInterval(() => {
    //   this.test();
    // }, 5000);
  },

  methods: {
    test() {
      setInterval(() => {
        axios({
          // url: "http://localhost:3000/data",
          //url: "http://47.97.46.240:50000/data",
          url: "https://api.hebaoze.com.cn/data",
          // url: "http://localhost:3000/data",

          method: "GET",
        }).then((res) => {
          this.tableData[0].Time = dayjs(new Date().toLocaleString()).format(
            "HH:mm:ss"
          );
          this.tableData[0].T1 = res.data[0].T1;
          this.tableData[0].T2 = res.data[0].T2;
          this.tableData[0].T3 = res.data[0].T3;
          this.tableData[0].T4 = res.data[0].T4;
          this.tableData[0].T5 = res.data[0].T5;
          this.tableData[0].T6 = res.data[0].T6;
          this.tableData[0].T7 = res.data[0].T7;
          this.tableData[0].T8 = res.data[0].T8;
          this.tableData[0].T9 = res.data[0].T9;
          this.tableData[0].T10 = res.data[0].T10;

          this.tableData[0].H1 = res.data[0].H1;
          this.tableData[0].H2 = res.data[0].H2;
          this.tableData[0].H3 = res.data[0].H3;
          this.tableData[0].H4 = res.data[0].H4;
          this.tableData[0].H5 = res.data[0].H5;
          this.tableData[0].H6 = res.data[0].H6;
          this.tableData[0].H7 = res.data[0].H7;
          this.tableData[0].H8 = res.data[0].H8;
          this.tableData[0].H9 = res.data[0].H9;
          this.tableData[0].H10 = res.data[0].H10;

          this.$bus.$emit("quanshujuTable", this.tableData);

          // console.log(res);
          // console.log(res.data[0]);
          // console.log(res.data[0].T1);
        });
      }, 5000);
    },

    getToken() {
      getOauthToken().then((res) => {
        if (res.status == 200) {
          // 200 代表成功
          // console.log(res.data);
          // 存入本地
          localStorage.setItem("UserToken", JSON.stringify(res.data));
          this.getNewToken();
        } else {
          // 否则失败
          alert("获取token失败，请检查！");
        }
      });
    },
    getNewToken() {
      // console.log("刷新token时间:", moment().format());
      let params = {
        refresh_token: JSON.parse(localStorage.getItem("UserToken"))
          .refresh_token,
        client_id: JSON.parse(localStorage.getItem("UserToken")).clientId,
        client_secret: JSON.parse(localStorage.getItem("UserToken"))
          .clientSecret,
      };
      getNewOauthToken(params).then((res) => {
        if (res.status == 200) {
          // 200 代表成功
          console.log(res.data);
          localStorage.setItem("UserToken", JSON.stringify(res.data));
          this.getList();
        } else {
          // 否则失败
          alert("刷新token失败，请检查！");
        }
      });
    },
    getList() {
      let item = JSON.parse(localStorage.getItem("UserToken"));
      let accessToken = item.access_token,
        clientId = item.clientId;

      let params = {
        userId: item.userId, // 用户id
        deviceId: 322693, // 设备id
        currPage: 1, //当前页码，必选参数，默认1 即第一页
        pageSize: 10, //每页返回的数据条数，可选参数,默认返回10条，最大设置不能超过100条
      };

      // //  body: JSON.stringify(params)

      // getSingleDeviceDatas(accessToken, clientId, params).then((res) => {
      //   console.log(res);
      // });
      getSingleDeviceDatas(accessToken, clientId, params).then((res) => {
        if (res.status == 200) {
          // 200 代表成功
          console.log(res);

          // this.tableData[0].Time = res.data.device.sensorsList[0].updateDate;
          dayjs(new Date().toLocaleString()).format("HH:mm:ss");

          this.tableData[0].Time = dayjs(new Date().toLocaleString()).format(
            "HH:mm:ss"
          );
          this.tableData[0].T1 = res.data.device.sensorsList[0].value;
          this.tableData[0].T2 = res.data.device.sensorsList[1].value;
          this.tableData[0].T3 = res.data.device.sensorsList[2].value;
          this.tableData[0].T4 = res.data.device.sensorsList[3].value;
          this.tableData[0].T5 = res.data.device.sensorsList[4].value;
          this.tableData[0].T6 = res.data.device.sensorsList[5].value;
          this.tableData[0].T7 = res.data.device.sensorsList[6].value;
          this.tableData[0].T8 = res.data.device.sensorsList[7].value;
          this.tableData[0].T9 = res.data.device.sensorsList[8].value;
          this.tableData[0].T10 = res.data.device.sensorsList[9].value;
          this.tableData[0].T11 = res.data.device.sensorsList[10].value;
          this.tableData[0].T12 = res.data.device.sensorsList[11].value;
          this.tableData[0].H1 = res.data.device.sensorsList[12].value;
          this.tableData[0].H2 = res.data.device.sensorsList[13].value;
          this.tableData[0].H3 = res.data.device.sensorsList[14].value;
          this.tableData[0].H4 = res.data.device.sensorsList[15].value;
          this.tableData[0].H5 = res.data.device.sensorsList[16].value;
          this.tableData[0].H6 = res.data.device.sensorsList[17].value;
          this.tableData[0].H7 = res.data.device.sensorsList[18].value;
          this.tableData[0].H8 = res.data.device.sensorsList[19].value;
          this.tableData[0].H9 = res.data.device.sensorsList[20].value;
          this.tableData[0].H10 = res.data.device.sensorsList[21].value;
          this.tableData[0].H11 = res.data.device.sensorsList[22].value;
          this.tableData[0].H12 = res.data.device.sensorsList[23].value;

          console.log(this.tableData[0].Time);

          this.$bus.$emit("quanshujuTable", this.tableData);
        } else {
          // 否则失败
          this.getToken();
          this.getList();
        }
      });
    },
  },
};
</script>

<style></style>
