<template>
  <div class="xianshi">
    <el-table
      :data="tableDataT"
      border
      style="width: 100%"
      header-align="center"
      align="center"
    >
      <el-table-column
        v-for="(item, index) in tableDataT[0]"
        :key="index"
        :prop="index"
        :label="index"
        width="100%"
        align="center"
      >
      </el-table-column>
    </el-table>

    <el-table
      :data="tableDataH"
      border
      style="width: 100%"
      header-align="center"
      align="center"
    >
      <el-table-column
        v-for="(item, index) in tableDataH[0]"
        :key="index"
        :prop="index"
        :label="index"
        width="100%"
        align="center"
      >
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
// <script src="../api/qingqiu.js">

import axios from "axios";
// import { getData } from '../api';
import dayjs from "dayjs";

// import { getDeviceSensorDatas } from "../api/index.js";

axios.defaults.withCredentials = true;

export default {
  data() {
    return {
      info: null,

      tableDataT: [
        {
          Time: "/",
          T1: "",
          T2: "",
          T3: "",
          T4: "",
          T5: "",
          T6: "",
          T7: "",
          T8: "",
          T9: "",
          T10: "",
          // T10:"20.00",
          // T11:"20.00",
          // T12:"20.00",
        },
      ],

      tableDataH: [
        {
          Time: "",
          H1: "",
          H2: "",
          H3: "",
          H4: "",
          H5: "",
          H6: "",
          H7: "",
          H8: "",
          H9: "",
          H10: "",
          // H11:"20.00",
          // H12:"20.00",
        },
      ],
    };
  },
  mounted() {
    
    // getData().then((data)=>{

    //   this.tableDataT=data.data.tableDataT
    //   this.tableDataH=data.data.tableDataH

    //   // console.log(this.tableDataT)

    // });



    this.$bus.$on('quanshujuTable',this.quanshujuTable2)


  },

   beforeDestroy() { 


    this.$bus.$off('quanshujuTable')
  },


  methods: {

    //接收TLINK的APP传来的数据

    quanshujuTable2(Table)
    {
        this.tableDataT[0].Time=Table[0].Time
        this.tableDataT[0].T1=Table[0].T1
        this.tableDataT[0].T2=Table[0].T2
        this.tableDataT[0].T3=Table[0].T3
        this.tableDataT[0].T4=Table[0].T4
        this.tableDataT[0].T5=Table[0].T5
        this.tableDataT[0].T6=Table[0].T6
        this.tableDataT[0].T7=Table[0].T7
        this.tableDataT[0].T8=Table[0].T8
        this.tableDataT[0].T9=Table[0].T9
        this.tableDataT[0].T10=Table[0].T10

        this.tableDataH[0].Time=Table[0].Time
        this.tableDataH[0].H1=Table[0].H1
        this.tableDataH[0].H2=Table[0].H2
        this.tableDataH[0].H3=Table[0].H3
        this.tableDataH[0].H4=Table[0].H4
        this.tableDataH[0].H5=Table[0].H5
        this.tableDataH[0].H6=Table[0].H6
        this.tableDataH[0].H7=Table[0].H7
        this.tableDataH[0].H8=Table[0].H8
        this.tableDataH[0].H9=Table[0].H9
        this.tableDataH[0].H10=Table[0].H10

        

    },


    // //温湿度传感器
    // send() {

    //   this.tableDataT[0].T1='222'

    //   // 先执行一次
    //   lastDataPoint([
    //             {
    //                 cusdeviceNo: '0000225119000004',
    //                 dataPointId: '23851406'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000004',
    //                 dataPointId: '23851407'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000004',
    //                 dataPointId: '23851408'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000004',
    //                 dataPointId: '23851409'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000004',
    //                 dataPointId: '23851423'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000004',
    //                 dataPointId: '23851429'
    //             },

    //             {
    //                 cusdeviceNo: '0000225119000004',
    //                 dataPointId: '23851425'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000004',
    //                 dataPointId: '23851463'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000004',
    //                 dataPointId: '23851426'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000004',
    //                 dataPointId: '23851464'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000004',
    //                 dataPointId: '23851427'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000004',
    //                 dataPointId: '23851465'
    //             },

    //             {
    //                 cusdeviceNo: '0000225119000004',
    //                 dataPointId: '23851428'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000004',
    //                 dataPointId: '23851466'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000004',
    //                 dataPointId: '24446149'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000004',
    //                 dataPointId: '24446151'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000004',
    //                 dataPointId: '24446150'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000004',
    //                 dataPointId: '24446152'
    //             },

    //         ]).then(res => {
    //         //   console.log(res.data);

    //           this.tableDataT[0].Time=dayjs(res.data.data.list[0].time).format('HH:mm')
    //           this.tableDataT[0].T1=res.data.data.list[0].value
    //           this.tableDataT[0].T2=res.data.data.list[2].value
    //           this.tableDataT[0].T3=res.data.data.list[4].value
    //           this.tableDataT[0].T4=res.data.data.list[6].value
    //           this.tableDataT[0].T5=res.data.data.list[8].value
    //           this.tableDataT[0].T6=res.data.data.list[10].value
    //           this.tableDataT[0].T7=res.data.data.list[12].value
    //           this.tableDataT[0].T8=res.data.data.list[14].value
    //           this.tableDataT[0].T9=res.data.data.list[16].value

    //           this.tableDataH[0].Time=dayjs(res.data.data.list[0].time).format('HH:mm')
    //           this.tableDataH[0].H1=res.data.data.list[1].value
    //           this.tableDataH[0].H2=res.data.data.list[3].value
    //           this.tableDataH[0].H3=res.data.data.list[5].value
    //           this.tableDataH[0].H4=res.data.data.list[7].value
    //           this.tableDataH[0].H5=res.data.data.list[9].value
    //           this.tableDataH[0].H6=res.data.data.list[11].value
    //           this.tableDataH[0].H7=res.data.data.list[13].value
    //           this.tableDataH[0].H8=res.data.data.list[15].value
    //           this.tableDataH[0].H9=res.data.data.list[17].value

    //         })

    //   setInterval( () => {
    //     lastDataPoint([
    //             {
    //                 cusdeviceNo: '0000225119000004',
    //                 dataPointId: '23851406'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000004',
    //                 dataPointId: '23851407'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000004',
    //                 dataPointId: '23851408'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000004',
    //                 dataPointId: '23851409'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000004',
    //                 dataPointId: '23851423'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000004',
    //                 dataPointId: '23851429'
    //             },

    //             {
    //                 cusdeviceNo: '0000225119000004',
    //                 dataPointId: '23851425'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000004',
    //                 dataPointId: '23851463'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000004',
    //                 dataPointId: '23851426'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000004',
    //                 dataPointId: '23851464'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000004',
    //                 dataPointId: '23851427'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000004',
    //                 dataPointId: '23851465'
    //             },

    //             {
    //                 cusdeviceNo: '0000225119000004',
    //                 dataPointId: '23851428'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000004',
    //                 dataPointId: '23851466'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000004',
    //                 dataPointId: '24446149'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000004',
    //                 dataPointId: '24446151'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000004',
    //                 dataPointId: '24446150'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000004',
    //                 dataPointId: '24446152'
    //             },

    //         ]).then(res => {
    //           console.log(res.data);

    //           this.tableDataT[0].Time=dayjs(res.data.data.list[0].time).format('HH:mm')
    //           this.tableDataT[0].T1=res.data.data.list[0].value
    //           this.tableDataT[0].T2=res.data.data.list[2].value
    //           this.tableDataT[0].T3=res.data.data.list[4].value
    //           this.tableDataT[0].T4=res.data.data.list[6].value
    //           this.tableDataT[0].T5=res.data.data.list[8].value
    //           this.tableDataT[0].T6=res.data.data.list[10].value
    //           this.tableDataT[0].T7=res.data.data.list[12].value
    //           this.tableDataT[0].T8=res.data.data.list[14].value
    //           this.tableDataT[0].T9=res.data.data.list[16].value

    //           // this.tableDataT[0].T10=res.data.data.list[18].value
    //           // this.tableDataT[0].T11=res.data.data.list[20].value
    //           // this.tableDataT[0].T12=res.data.data.list[22].value

    //           this.tableDataH[0].Time=dayjs(res.data.data.list[0].time).format('HH:mm')
    //           this.tableDataH[0].H1=res.data.data.list[1].value
    //           this.tableDataH[0].H2=res.data.data.list[3].value
    //           this.tableDataH[0].H3=res.data.data.list[5].value
    //           this.tableDataH[0].H4=res.data.data.list[7].value
    //           this.tableDataH[0].H5=res.data.data.list[9].value
    //           this.tableDataH[0].H6=res.data.data.list[11].value
    //           this.tableDataH[0].H7=res.data.data.list[13].value
    //           this.tableDataH[0].H8=res.data.data.list[15].value
    //           this.tableDataH[0].H9=res.data.data.list[17].value

    //         })
    //   }

    //       ,10000)

    // },

    //温度采集模块

    // send2() {

    //   // 先执行一次
    //   lastDataPoint([
    //             {
    //                 cusdeviceNo: '0000225119000007',
    //                 dataPointId: '24114177'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000007',
    //                 dataPointId: '24114178'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000007',
    //                 dataPointId: '24114179'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000007',
    //                 dataPointId: '24114180'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000007',
    //                 dataPointId: '24114190'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000007',
    //                 dataPointId: '24114191'
    //             },

    //             {
    //                 cusdeviceNo: '0000225119000007',
    //                 dataPointId: '24114192'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000007',
    //                 dataPointId: '24114194'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000007',
    //                 dataPointId: '24114196'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000007',
    //                 dataPointId: '24114197'
    //             },

    //         ]).then(res => {
    //         //   console.log(res.data);

    //           this.tableDataT[0].Time=dayjs(res.data.data.list[0].time).format('HH:mm')
    //           this.tableDataT[0].T1=res.data.data.list[0].value
    //           this.tableDataT[0].T2=res.data.data.list[1].value
    //           this.tableDataT[0].T3=res.data.data.list[2].value
    //           this.tableDataT[0].T4=res.data.data.list[3].value
    //           this.tableDataT[0].T5=res.data.data.list[4].value
    //           this.tableDataT[0].T6=res.data.data.list[5].value
    //           this.tableDataT[0].T7=res.data.data.list[6].value
    //           this.tableDataT[0].T8=res.data.data.list[7].value
    //           this.tableDataT[0].T9=res.data.data.list[8].value
    //           this.tableDataT[0].T10=res.data.data.list[9].value

    //         })

    //   setInterval( () => {
    //     lastDataPoint([
    //             {
    //                 cusdeviceNo: '0000225119000007',
    //                 dataPointId: '24114177'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000007',
    //                 dataPointId: '24114178'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000007',
    //                 dataPointId: '24114179'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000007',
    //                 dataPointId: '24114180'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000007',
    //                 dataPointId: '24114190'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000007',
    //                 dataPointId: '24114191'
    //             },

    //             {
    //                 cusdeviceNo: '0000225119000007',
    //                 dataPointId: '24114192'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000007',
    //                 dataPointId: '24114194'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000007',
    //                 dataPointId: '24114196'
    //             },
    //             {
    //                 cusdeviceNo: '0000225119000007',
    //                 dataPointId: '24114197'
    //             },

    //         ]).then(res => {
    //           console.log(res.data);

    //             this.tableDataT[0].Time=dayjs(res.data.data.list[0].time).format('HH:mm')
    //             this.tableDataT[0].T1=res.data.data.list[0].value
    //             this.tableDataT[0].T2=res.data.data.list[1].value
    //             this.tableDataT[0].T3=res.data.data.list[2].value
    //             this.tableDataT[0].T4=res.data.data.list[3].value
    //             this.tableDataT[0].T5=res.data.data.list[4].value
    //             this.tableDataT[0].T6=res.data.data.list[5].value
    //             this.tableDataT[0].T7=res.data.data.list[6].value
    //             this.tableDataT[0].T8=res.data.data.list[7].value
    //             this.tableDataT[0].T9=res.data.data.list[8].value
    //             this.tableDataT[0].T10=res.data.data.list[9].value

    //           // this.tableDataT[0].T10=res.data.data.list[18].value
    //           // this.tableDataT[0].T11=res.data.data.list[20].value
    //           // this.tableDataT[0].T12=res.data.data.list[22].value

    //         })
    //   }

    //       ,10000)

    // },

    

    //把显示的数据传给记录组件，注意别和geishuju混了，那个是记录的大数组表格传给aside，然后存成excel用的
    chuanwendushuju() {
      this.$bus.$emit("wendushuju", this.tableDataT[0]);
    },
    // getToken() {
    //   getOauthToken().then((res) => {
    //     if (res.status == 200) {
    //       // 200 代表成功
    //       console.log(res.data);
    //       localStorage.setItem("UserToken", JSON.stringify(res.data));
    //     } else {
    //       // 否则失败
    //       alert("获取token失败，请检查！");
    //     }
    //   });
    // },
  },
};
</script>

<style scoped>
/* 
.beijing{

  padding: 50px;
  background-color: rgba(255, 0, 0, 0.8); /* 50% 透明的红色 */
/* border: 30px */
/* box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) 

} */
.xianshi {
  /* padding: 10px; */
  margin-bottom: 40px;
  background-color: rgba(5, 4, 4, 0.2);
  /* border: 40px; */
  box-shadow: 0 2px 12px 0 rgb(82, 221, 214, 0.7);
  /* height: 300px; */
}

.el-table {
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.1); /* 50% 透明的红色*/
  /* border-block-color:rgba(226, 212, 212, 0.8); 50% 透明的红色 ; */
}
.el-table::before {
  height: 0px;
}

/* .el-table .el-table__header-wrapper  {
  height: 50px; 
} */
</style>
