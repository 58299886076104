


export default {

    getStatisticalData: () => {


        return {
            // tableDataT: [

            //     {
            //         Time: shijian,
            //         T1: wendu[0],
            //         T2: wendu[1],
            //         T3: wendu[2],
            //         T4: wendu[3],
            //         T5: wendu[4],
            //         T6: wendu[5],
            //         T7: wendu[6],
            //         T8: wendu[7],
            //         T9: wendu[8],

            //     },

            // ],

            // tableDataH: [

            //     {
            //         Time: shijian,
            //         H1: shidu[0],
            //         H2: shidu[1],
            //         H3: shidu[2],
            //         H4: shidu[3],
            //         H5: shidu[4],
            //         H6: shidu[5],
            //         H7: shidu[6],
            //         H8: shidu[7],
            //         H9: shidu[8],
            //     },
            // ],

            tableDataT: [

                {
                    Time: "20:35:30",
                    T1: "20.00",
                    T2: "20.01",
                    T3: "20.02",
                    T4: "20.03",
                    T5: "20.04",
                    T6: "20.05",
                    T7: "20.06",
                    T8: "20.07",
                    T9: "20.08",
                    T10: "20.00",
                    T11: "20.00",
                    T12: "20.00",
                },

            ],

            tableDataH: [

                {
                    Time: "20:35:30",
                    H1: "40.00",
                    H2: "50.00",
                    H3: "60.00",
                    H4: "70.00",
                    H5: "80.00",
                    H6: "20.00",
                    H7: "20.00",
                    H8: "20.00",
                    H9: "20.00",
                    H10: "20.00",
                    H11: "20.00",
                    H12: "20.00",
                },
            ]

        }

    }


}




