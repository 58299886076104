import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import XiuZheng from '../views/XiuZheng.vue'
import Main from '../views/Main.vue'
import HuanJingShiYanSheBei from '../views/HuanJingShiYanSheBei.vue'
import YingErPeiYangXiang from '../views/YingErPeiYangXiang.vue'

import wendujilu from '../components/wendujilu.vue'
import shidujilu from '../components/shidujilu.vue'


Vue.use(VueRouter)

const routes = [
    {
        path: '/',//主路由
        component: Main,
        children: [

            {
                path: '/Home',
                component: Home,
                children: [
                    { path: '/wendujilu', component: wendujilu },
                    { path: '/shidujilu', component: shidujilu },

                ],

            },

            { path: '/XiuZheng', component: XiuZheng },
            { path: '/HuanJingShiYanSheBei', component: HuanJingShiYanSheBei },
            { path: '/YingErPeiYangXiang', component: YingErPeiYangXiang },


        ]

    },

]


const router = new VueRouter({
    routes // (缩写) 相当于 routes: routes
})

export default router

