<template>
 
      <div class="jilu" >
        <div class="neibu"> 
            <el-col :span=30>

                <el-menu
                
                background-color="#545c64"
                text-color="#fff"
                active-text-color="#ffd04b"
                :router="true">
                
                <el-menu-item index="wendujilu">
                    <i class="el-icon-sunny"></i>
                    <span slot="title">温度</span>
                </el-menu-item>
                <el-menu-item index="shidujilu">
                    <i class="el-icon-light-rain"></i>
                    <span slot="title">湿度</span>
                </el-menu-item>
                
                </el-menu>
                

            </el-col>

        </div>

         <div class="neibu">   
            
            <keep-alive>
            <router-view></router-view>
            </keep-alive>

          </div>

      </div>
      
        



</template>



<script>


import axios from 'axios';
import { getData } from '../utils';




 export default {
    data() {
      return {
       

      }
    },
methods: {

   

    
  },


    mounted() {
      
    //   getData().then((data)=>{
        
    //     this.tableDataT=data.data.tableDataT
        
    //     console.log(this.tableDataT)

    //   })

    }

  }


</script>

<style scoped>


 .jilu{
  /* padding: 20px; */
  /* margin-bottom: 40px; */
  /*background-color: rgba(226, 212, 212, 0.8); 50% 透明的红色*/
  /* border: 40px; */
  box-shadow: 0 2px 12px 0 rgb(82, 221, 214,0.7) ;
  height: 450px;
  background-color: rgba(5, 4, 4, 0.2); 
  display: flex;

  /* height: 300px; */

}
.neibu{

border-color:rgba(54, 206, 24, 0); 
border-width: 0px;
border: none;

}

.el-table{
  
  padding-left: 40px;
  padding-top: 20px;
  
  background-color: rgba(226, 212, 212, 0.0);
  
  
  border-color:rgba(54, 206, 24, 0.0); 
  
}

.el-table::before{

    height: 0px;
}

.el-table--border::after{

    width: 0px;
}

.el-button{

    margin-top: 10px;
}


/* .el-table .el-table__header-wrapper  {
  height: 50px; 
} */


</style>


