<template>
    
<h1>环境试验设备</h1>

</template>

<script>
export default {
        
    data(){

        return {

        }
    }

}
</script>