<template>
  <div>
    <el-container>
      <el-header
        style="height: 110px; color: blue; font-size: 50px; padding-top: 1%"
      >
        <img
          src="../../public/LOGO.jpeg"
          width="100"
          height="100"
          style="float: left; margin: -10px"
        />

        量 传 计 量 -- 温 度 采 集 系 统
      </el-header>

      <el-container>
        <el-aside width="200px">
          <my-aside></my-aside>
        </el-aside>

        <el-main class="background-main">
          <keep-alive>
            <router-view></router-view>
          </keep-alive>
        </el-main>
      </el-container>

      <el-footer></el-footer>
    </el-container>
  </div>
</template>

<script>
import myAside from "../components/myAside.vue";

export default {
  data() {
    return {};
  },

  components: {
    myAside: myAside,
  },
};
</script>

<style scoped>
.background-main {
  background-image: url("../../public/beijingvue.png");
  background-size: cover;
  background-position: center;

  color: #0e5da7;
}

.el-header,
.el-footer {
  background-color: #c7ccdf;
  color: #423c3c;
  text-align: center;
  line-height: 50px;
}

.el-aside {
  background-color: #d3dce6;
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-main {
  background-color: #e9eef3;
  color: #333;
  text-align: center;
  /* line-height: 40px; */
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
</style>
