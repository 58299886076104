<template>

<div>

    <!-- 导出excel -->
    <div v-show="false">
        <!-- <el-button @click="daochu">导出Excel</el-button> -->
        <el-table
            ref="elTable"
            :data="tableData"
            style="width: 100%">
            
            <el-table-column v-for="(item, index) in tableData[0]" :key="index"
                :prop=index
                :label=index
                width=100%
                align="center">
                
            </el-table-column>
            
        </el-table>
    </div>


<el-row class="tac">
 
  <el-col >

    <el-menu
      style="height: 400px"
      default-active="2"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b"
      :router="true">



      <!-- <el-submenu index="Home">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>主界面</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="wendujiemian">温度</el-menu-item>
          <el-menu-item index="wenshidujiemian">温湿度</el-menu-item>
        </el-menu-item-group>
       </el-submenu> -->

      <el-menu-item index="Home">
        <i class="el-icon-location"></i>
        <span slot="title">主界面</span>
      </el-menu-item>

      <el-menu-item index="XiuZheng">
        <i class="el-icon-setting"></i>
        <span slot="title">修正值</span>
      </el-menu-item>

      <el-submenu index="不用定义">
        <template slot="title">
          <i class="el-icon-document"></i>
          <span>规程</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="HuanJingShiYanSheBei">环境实验设备</el-menu-item>
          <el-menu-item index="YingErPeiYangXiang">婴儿培养箱</el-menu-item>
        </el-menu-item-group>
       
      </el-submenu>

    </el-menu>

  </el-col>
  
</el-row>

            <div class="peizhi">

                <div style="font-size:15px; color:white;margin-top: 0px;">
                    请输入采样频率（s）
                </div>
                
                

                <el-input v-model="input" placeholder="采集频率" center
                style="width:100px;"
                ></el-input>
                
                <br>

                 <el-button type="primary" style="margin-top: 30px;"
                 @click="kaishi" :disabled="disabled1"
                 >开始采集</el-button>

                 <br>

                 <el-button type="warning" :disabled="disabled2" style="margin-top: 20px; "
                  @click="jieshu"
                 >停止采集</el-button>

                <br>



                
                <el-button type="danger" style="margin-top: 20px;"
                @click="dialogVisible=true"
                
                >清空数据</el-button>

                

                <el-dialog
                  title="提示"
                  :visible.sync="dialogVisible"
                  width="25%"
                  >
                  <span>确认清空记录数据？</span>
                  <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="qingkong">确 定</el-button>
                  </span>
                </el-dialog>


                
                
                
                <br>

                <el-button type="success" style="margin-top: 20px;" :disabled="disabled3"
                @click="daochu"
                >导出数据</el-button>
                
            </div>


    </div>


</template>



<script>

    import { saveAs } from 'file-saver';

    // import XLSX from 'xlsx';

    import * as XLSX from 'xlsx/xlsx.mjs'



  export default {

    data(){
        return{

          input:10,

          dialogVisible:false,
            
          disabled1: false,
          disabled2: true,
          disabled3: false,//导出数据

          qingkongshuju:false,


          tableData: [{
              
                    Time: "112",
                    T1: "12",
                    T2: "123",
                    T3: "123",
                    T4: "",
                    T5: "",
                    T6: "",
                    T7: "",
                    T8: "",
                    T9: "",
                    T10: "",
                    T11: "",
                    T12: "",

                    Time: "",
                    H1: "",
                    H2: "",
                    H3: "",
                    H4: "",
                    H5: "",
                    H6: "",
                    H7: "",
                    H8: "",
                    H9: "",
                    H10: "",
                    H11: "",
                    H12: "",

          }],
          wendushujuValue:[],
          shidushujuValue:[],

          


          // shujuValue:[],

          // shujuValue:[...wendushujuValue, ...shidushujuValue],


        }
    },

    methods: {


      exportToExcel() {
          // 获取表格的DOM元素
          const elTable = this.$refs.elTable;
          const wb = XLSX.utils.table_to_book(elTable.$el);
          // 生成Excel的配置项
          const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' });
          try {
            // 如果是IE浏览器，使用Blob通过navigator.msSaveBlob保存
            if (navigator.msSaveBlob) {
              navigator.msSaveBlob(new Blob([wbout], { type: 'application/octet-stream' }), '导出数据.xlsx');
            } else {
              // 其他浏览器使用file-saver保存
              saveAs(new Blob([wbout], { type: 'application/octet-stream' }), '导出数据.xlsx');
            }
          } catch (e) {
            if (typeof console !== 'undefined') console.log(e, wbout);
          }
          return wbout;
      },





      handleOpen(key, keyPath) {
        console.log(key, keyPath);
      },
      handleClose(key, keyPath) {
        console.log(key, keyPath);
      },

      kaishi() {
      this.disabled1 = true;
      this.disabled2 = false;
      this.disabled3 = true;


      this.$bus.$emit('jiluzhuangtai',this.disabled1)
      this.$bus.$emit('caijipinlv',this.input)
      // console.log(this.disab)

      },
      jieshu() {

        this.disabled1 = false;
        this.disabled2 = true;
        this.disabled3 = false;

        this.$bus.$emit('jiluzhuangtai',this.disabled1)
        this.$bus.$emit('caijipinlv',this.input)

        //本来是在导出记录里的，现在分部进行，先停止采集后再导出
        this.$bus.$emit('geiwendushuju')//先给温度数据vue信号，让他发数据到这个组件
        this.$bus.$emit('geishidushuju')//先给湿度数据vue信号，让他发数据到这个组件


        
      },

      qingkong() {
        
      // 执行操作
        
      this.qingkongshuju = true;
      this.$bus.$emit('qingkong',this.qingkongshuju)


      this.dialogVisible = false;

        
      },


      wendushuju(wendushuju2){


        this.wendushujuValue=wendushuju2;




      },


      shidushuju(shidushuju2){

        this.shidushujuValue=shidushuju2;

        // this.tableData=[...this.wendushujuValue, ...this.shidushujuValue];
        this.tableData=this.wendushujuValue.concat(this.shidushujuValue)
        
        // console.log(this.tableData)
        // this.tableData.concat(this.wendushujuValue,this.shidushujuValue)

      },

      
      


      daochu() {
        

        ////这两步已移植停止扭
      // this.$bus.$emit('geiwendushuju')//先给温度数据vue信号，让他发数据到这个组件
      // this.$bus.$emit('geishidushuju')//先给湿度数据vue信号，让他发数据到这个组件

      //到温度和湿度的数据，拿到aside这边，然后再传给daochuExcel
      // this.$bus.$emit('jieshoushujuToExcel',this.shujuValue)


      // //暂时屏蔽，不会合并数组
      // this.exportToExcel()
      
      //先启用，不会合并数组
      this.$bus.$emit('daochushuju')

      },

    },

    mounted() {
      
      this.$bus.$on('wendushuju',this.wendushuju)//紧接着接受发来的数据，
      this.$bus.$on('shidushuju',this.shidushuju)//紧接着接受发来的数据，


      // kaishi() 
      // jieshu() 

      // this.$bus.$on('exportToExcel',this.exportToExcel)//
      
      
    },

    beforeDestroy() { 

      this.$bus.$off('wendushuju');
      this.$bus.$off('shidushuju');
      // this.$bus.$off('exportToExcel');
    },

    

  }
</script>

<style scoped>

.peizhi{
  height: 500px;
  background-color:#545c64;
  /* background-color: #D3DCE6; */
    color: #333;
    text-align: center;
    line-height: 40px;
    border:solid 1px #e6e6e6
}
  /* .el-header, .el-footer {
    background-color: #B3C0D1;
    color: #333;
    text-align: center;
    line-height: 60px;
  }
  
  .el-aside {
    background-color: #D3DCE6;
    color: #333;
    text-align: center;
    line-height: 200px;
  }
  
  .el-main {
    background-color: #E9EEF3;
    color: #333;
    text-align: center;
    line-height: 160px;
  }
  
  body > .el-container {
    margin-bottom: 40px;
  }
  
  .el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    line-height: 260px;
  }
  
  .el-container:nth-child(7) .el-aside {
    line-height: 320px;
  } */
</style>
